// ===== format the price data, this function might need some changing once we have language and currency for each product =======
export const priceFormatter = (price) => {
  const formatter = new Intl.NumberFormat('lt-LT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })

  const formattedPrice = formatter.format(price)

  return formattedPrice
}

export const discountSubtractor = (price, discountPercentage) => {
  if (discountPercentage) {
    const fullPrice = price
    const discountAmount = price * (discountPercentage / 100)
    const priceAfterDiscont = fullPrice - discountAmount
    return priceAfterDiscont
  } else {
    return price
  }
}

// Accepts a discount and returns true / false if discount is good to applu
export const discountCheckUtility = (discountObject) => {
  let check = true
  if (discountObject) {
    // If there is a discount we check if its active.
    if (discountObject.active) {
      // If its active we check if the start date has already passed.
      if (discountObject.startDate) {
        if (new Date(discountObject.startDate).getTime() > Date.now()) {
          check = false
        }
      }
      // If its active we check if the end date has already passed.
      if (discountObject.expiryDate) {
        if (new Date(discountObject.expiryDate).getTime() < Date.now()) {
          check = false
        }
      }
    }
  } else {
    return false
  }
  return check
}

//* ====================================================================================================================================
//* ================================================ Calculate discounted product container ============================================
//* ====================================================================================================================================
//These are for DISCOUNTS, which are always in percentage (not to confuse with coupons, which can be fixed or percentage)
export const getDiscountedProductPrice = (price, percentageDiscount) => {
  // const { current_prices_with_vat, vat_percentage } = shopSettingsVar() as any;

  // TODO Get this from shop settings!!!!!! ========
  const current_prices_with_vat = true
  const vat_percentage = 21

  let discountedPrice = null

  if (current_prices_with_vat) {
    let priceWithoutVat = calculatePriceWithoutVat(price, vat_percentage)
    let discountedPriceWithouVat =
      (priceWithoutVat * (100 - percentageDiscount)) / 100
    discountedPrice = calculatePriceWithVat(
      discountedPriceWithouVat,
      vat_percentage,
    )
  } else {
    discountedPrice = (price * (100 - percentageDiscount)) / 100
  }

  return discountedPrice
}

export const getPercentageDiscountAmount = (price, percentageDiscount) => {
  let discountedAmount = (price * percentageDiscount) / 100

  return discountedAmount
}

//* ====================================================================================================================================
//* ================================================ Calculate total_price_without_VAT =================================================
//* ====================================================================================================================================
export const calculatePriceWithoutVat = (price, VAT) => {
  const priceWithoutVat = price / ((100 + VAT) / 100)
  return priceWithoutVat
}

//* ==========================================================================================================================================
//* ====================================================== Calculate price WITH VAT ==========================================================
//* ==========================================================================================================================================
export const calculatePriceWithVat = (price, VAT) => {
  const amountToAdd = price * (VAT / 100)
  const priceWithVat = price + amountToAdd

  return priceWithVat
}

//* =================================================================================================================================================
//* ====================================================== Calculate VAT amount from price ==========================================================
//* =================================================================================================================================================
//This function gives back the amount that should be added on top of the given price without VAT (e.g. given the subtotal without VAT, we want to display how much VAT will be added on top to get the total price)
export const calculateVatAmount = (priceWithoutVat, VAT) => {
  return priceWithoutVat * (VAT / 100)
}
