import { gql } from "@apollo/client"

export const GET_CUSTOMER_GROUPS = gql`
  query GET_CUSTOMER_GROUPS(
    $searchTerm: String
    # $searchTermInteger: Int
    # $language: EnumLanguageFilter
    $take: Int
    $skip: Int
    $orderBy: [CustomerGroupOrderByWithRelationInput!]
  ) {
    findManyCustomerGroupCount(
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
        ]
      }
    )

    findManyCustomerGroup(
      orderBy: $orderBy
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
        ]
      }
      take: $take
      skip: $skip
    ) {
      # ----- The customer
      id
      createdAt
      name
      _count {
      Customer
      }
    }
  }
`

export const DELETE_SINGLE_CUSTOMER_GROUP = gql`
  mutation DELETE_SINGLE_CUSTOMER_GROUP($id: Int!) {
    deleteOneCustomerGroup(where: { id: $id }) {
      id
    }
  }
`

export const CORE_CUSTOMER_GROUP_DATA = gql`
  fragment CoreCustomerGroupData on CustomerGroup {
    id
    createdAt
    updatedAt

    name
  }
`

//  TODO ======
export const CREATE_CUSTOMER_GROUP = gql`
  ${CORE_CUSTOMER_GROUP_DATA}
  mutation CREATE_CUSTOMER_GROUP(
    $name: String!
  ) {
    createCustomerGroup(
      name: $name
    ) {
      ...CoreCustomerGroupData
    }
  }
`

export const UPDATE_CUSTOMER_GROUP = gql`
  ${CORE_CUSTOMER_GROUP_DATA}
  mutation UPDATE_CUSTOMER_GROUP(
    $where: CustomerGroupWhereUniqueInput!
    $name: String!
  ) {
    updateCustomerGroup(
      where: $where
      name: $name
    ) {
      ...CoreCustomerGroupData
    }
  }
`

export const GET_CUSTOMER_GROUP = gql`
  ${CORE_CUSTOMER_GROUP_DATA}
  query GET_CUSTOMER_GROUP($id: Int!) {
    findUniqueCustomerGroup(where: { id: $id }) {
      ...CoreCustomerGroupData
    }
  }
`