import React, { useCallback, useEffect, useState } from 'react'
import { styled, withStyle, createThemedUseStyletron } from 'baseui'
import { Grid, Row, Col } from 'components/FlexBox/FlexBox'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import {
  useQuery,
  gql,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client'
import { Wrapper, Header, Heading } from 'components/Wrapper.style'
import ThemeButton from 'components/Button/Button'
// import {Button} from 'baseui/button';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button'
import NoResult from 'components/NoResult/NoResult'
import { useDrawerDispatch } from 'context/DrawerContext'
import DataTable from 'react-data-table-component'
import { Check, Delete, Plus, Spinner } from 'baseui/icon'
import { FiEdit, FiArrowRight, FiFrown, FiEyeOff } from 'react-icons/fi'
import { RiContactsBookLine, RiDeleteBin2Line } from 'react-icons/ri'
import useDebounce from '../../utils/hooks/debounce'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { SiteLanguageVar } from 'lib/reactiveVars'
import { largeWidth, midWidth, sm, smallWidth } from 'settings/constants'
import { extractProfileByLanguage } from 'utils/languages'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { priceFormatter } from 'utils/priceUtils'
import { Tag, VARIANT } from 'baseui/tag'
import { orderNumberFormatter } from 'utils'

import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { FlagComponent } from 'components/Flag/FlagComponent'
import { OrderDateComponent } from 'components/OrderDate/OrderDate'
import { OrderStatusComponent } from 'components/OrderStatus/OrderStatusComponent'
import { ConstructionOutlined } from '@mui/icons-material'
import {
  checkDiscountValidity,
  checkDiscountValidityBoolean,
} from 'utils/discountValidityCheck'
import usePaging from 'utils/hooks/usePaging'
import { DELETE_SINGLE_CUSTOMER_GROUP, GET_CUSTOMER_GROUPS } from './queries'
import { showErrorNotification, showSuccessNotification } from 'utils/notification'

type CustomThemeT = { red400: string; textNormal: string; colors: any }
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()

export const CustomerGroupsTable = ({ searchTerm, updateRow }) => {
    const [useCss, theme] = themedUseStyletron()
    const confirm = useConfirmation()
    const { enqueue } = useSnackbar()
    // We want to pass the language, ONLY when we have a search term.
    const siteLanguage = useReactiveVar(SiteLanguageVar)
    let language = undefined
    if (searchTerm) {
      language = { equals: siteLanguage }
    }
  
    const [selectedRows, setSelectedRows] = useState(null)
    const [selectedCount, setSelectedCount] = useState(0)
    const [resetSelectedRows, setResetSelectedRows] = useState(false)
    const [orderBy, setOrderBy] = useState(undefined) // (SORTING)
    const { pageNumber, paginationPerPage, onChangePage, onChangeRowsPerPage, resetPaginationToggle, getFilters } = usePaging(1, 50);
    const [data, setData] = useState(undefined)
  
    // We need to set a default orderBy...
    useEffect(() => {
      setOrderBy({
        createdAt: 'desc',
      })
    }, [])
  
    //* ==== Mutation to edita data in database
    const [
      deleteSingleCustomer,
      {
        data: mutationData,
        called,
        loading: mutationLoading,
        error: mutationError,
      },
    ] = useMutation(DELETE_SINGLE_CUSTOMER_GROUP, {
      onError: (e) => {
        // For mutation errors...
        console.log('Mutation error!', e)
        console.log('Extracted error!', e.graphQLErrors)
        showErrorNotification(enqueue, "Error!")
      },
      onCompleted: (e) => {
        // Handle the success case.
        showSuccessNotification(enqueue, "Deleted Successfully")
        setResetSelectedRows(!resetSelectedRows)
      },
      refetchQueries: ['GET_CUSTOMER_GROUPS'],
    })
  
    // === GRAPHQL Query to get Colors
    const [fetchDataLazy, { loading, error, data: queryData }] = useLazyQuery(
      GET_CUSTOMER_GROUPS,
      {
        variables: {
          searchTerm,
          searchTermInteger: isNaN(parseInt(searchTerm))
            ? undefined
            : parseInt(searchTerm),
          language,
          ...getFilters(),
          orderBy,
        },
        fetchPolicy: 'cache-and-network',
      },
    )
  
    useEffect(() => {
      if (queryData) {
        setData(queryData)
      }
    }, [queryData])
  
    // We need this useEffec to control refetching of information, when state changes.
    // We fetch only when information related to the query changes.
    useEffect(() => {
      // console.log("Fetching...");
      fetchDataLazy()
    }, [
      searchTerm,
      siteLanguage,
      paginationPerPage,
      pageNumber,
      fetchDataLazy,
    ])
  
    // ======== DATA MIGRATION FUNCTIONS (above) =====================================================================
    // ---------------------------------------------------------------------------------------------------------------
  
    if (!data) return <InLineLoader />
    if (error) {
      return <div>Error! {error.message}</div>
    }
  
    // ================ TABLE PAGINATION ========================
    const totalRows = data.findManyCustomerGroupCount // This variable comes from Graphql query.
  
    // ================ TABLE PAGINATION (above) ================
    // ================ TABLE SORTING ================
    const handleSort = (column, sortDirection: 'desc' | 'asc') => {
      switch (column.selector) {
        case 'id':
          setOrderBy({ id: sortDirection })
          break
        case 'number_of_customers':
          //setOrderBy({ products: { _count: sortDirection } })
          break
        default:
          setOrderBy({ createdAt: 'desc' }) // Set default to be sorted (newest first)
      }
    }
    // ================ TABLE SORTING (above) ================
  
    const handleAction = (id) => {
      // Update drawer open is in the parent component
      updateRow(id)
    }
  
    const handleDelete = (id) => {
      // Confirm pops up a dialog asks if user wants to continue?
      confirm({
        variant: 'danger',
        title: 'Are you sure?',
        description: 'You will delete this Customer Group.',
      }).then(() => deleteOneCustomer(id))
    }
  
    const deleteOneCustomer = (id) => {
      // Delete this id.
      deleteSingleCustomer({
        variables: { id },
      })
    }
  
    const deleteMany = async () => {
      // selectedRows --> Delete all in this list
      if (selectedRows) {
        const itemsToDelete = await extractIDsToList(selectedRows)
        confirm({
          variant: 'danger',
          // catchOnCancel: true,
          title: `Are you sure, you want to DELETE (${selectedCount}) Customer Groups?`,
          description: '...',
        }).then(() => {
          // console.log("Deleting many colors -->", colorsToDelete);
          for (const itemId of itemsToDelete) {
            deleteSingleCustomer({
              variables: { id: itemId },
            })
          }
        })
      } else {
        // Say something...
        alert('Nothing selected..')
      }
    }
  
    const handleSelectedRowsChange = ({
      allSelected,
      selectedCount,
      selectedRows,
    }) => {
      setSelectedRows(selectedRows)
      setSelectedCount(selectedCount)
    }
  
    //This is a UTILITY FUNCTION. Not moving out, because might be closely associated with table rows.
    const extractIDsToList = (list) => {
      let newList = [] as any
      for (const item of list) {
        if (item.id) {
          newList.push(item.id)
        } else {
          alert('Selected items have no ids...')
        }
      }
      return newList
    }
  
    const columns = [
      {
        name: 'ID',
        selector: 'id',
        hide: sm,
        sortable: true,
        width: smallWidth,
      },
  
      {
        name: 'Date',
        selector: 'createdAt',
        hide: sm,
        sortable: true,
        width: largeWidth,
      },
  
      {
        name: `Name`,
        selector: 'name',
        sortable: false,
      },
      {
        name: `Count of Customers`,
        selector: 'number_of_customers',
        sortable: false,
      },
  
      {
        name: 'Delete',
        sortable: false,
        button: true,
        cell: (row) => (
          <Button
            kind={KIND.minimal}
            size={SIZE.compact}
            shape={SHAPE.circle}
            onClick={() => handleDelete(row.id)}
          >
            <RiDeleteBin2Line color={'red'} size={'1.3em'} />
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        width: smallWidth,
      },
  
      {
        name: 'View',
        sortable: false,
        button: true,
        cell: (row) => (
          <Button
            kind={KIND.minimal}
            size={SIZE.compact}
            shape={SHAPE.circle}
            onClick={() => handleAction(row.id)}
          >
            <FiArrowRight size={'1.5em'} />
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        width: smallWidth,
      },
    ]
  
    const dataModified = data.findManyCustomerGroup.map((customerGroup) => {
      const name = customerGroup ? customerGroup.name : '(empty)'
  
      return {
        id: customerGroup.id,
        createdAt: <OrderDateComponent dateTime={customerGroup.createdAt} />,
        name: <strong>{name}</strong>,
        number_of_customers: customerGroup?._count?.Customer ?? '-'      }
    })
  
    const customStyles = {
      rows: {
        style: {
          minHeight: '72px', // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          fontSize: '14px',
        },
      },
      cells: {
        style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
        },
      },
    }
  
    return (
      <Grid fluid={true}>
        <DataTable
          // title="Colors List" // Not displayed when noHeader=true
          customStyles={customStyles}
          columns={columns}
          data={dataModified}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          onRowClicked={(row) => handleAction(row.id)}
          paginationRowsPerPageOptions={[25, 50, 100, 200]}
          // subHeader={false}
  
          subHeader={true}
          subHeaderComponent={
            <SubHeader
              onDelete={deleteMany}
              rowsSelected={selectedCount > 0 ? true : false}
              rowsCount={selectedCount}
              loading={loading}
            />
          }
          noContextMenu={true} // To remove the blue bar showing how many rows you have selected
          // noHeader={true}
          noDataComponent={
            <NoResult
              hideButton={true}
              message={
                "No customer groups yet!"
              }
            />
          }
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={paginationPerPage}
          sortServer
          onSort={handleSort}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          paginationResetDefaultPage={resetPaginationToggle}
          selectableRows
          onSelectedRowsChange={handleSelectedRowsChange}
          clearSelectedRows={resetSelectedRows}
          disabled={loading}
        />
      </Grid>
    )
  }

  const SubHeader = ({ onDelete, rowsSelected, rowsCount, loading }) => {
    return (
      <>
        <Row style={{ width: '100%', padding: 0 }}>
          <Col md={12}>
            <Col md={6} style={{ float: 'left' }}>
              <Button
                kind={KIND.secondary}
                size={SIZE.compact}
                disabled
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.textNormal,
                      marginLeft: '-40px',
                    }),
                  },
                }}
              >
                Selected: {rowsCount}
              </Button>
              {loading ? <Spinner /> : null}
            </Col>
            <Col md={6} style={{ float: 'right' }}>
              <Button
                onClick={onDelete}
                size={SIZE.compact}
                disabled={!rowsSelected}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      backgroundColor: $theme.colors.red400,
                    }),
                  },
                }}
              >
                Delete
              </Button>
            </Col>
          </Col>
        </Row>
      </>
    )
  }